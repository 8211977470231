<template>
  <div>
    <p>Xero Integration Status: {{ xeroIntegrationStatus }}</p>
    <div>
    <!-- <button @click="importTransactions">Import Transactions to Xero</button>
    <br />
    <button @click="fetchXeroBankTransactions">Fetch Xero Bank Transactions</button> -->

  </div>
    <CustomerIndividualTable />
  </div>
</template>

<script>
import { setupXeroConnection } from '../../../composables/xero.js';
import CustomerIndividualTable from '../components/CustomerIndividualTable';
import axios from 'axios';

export default {
  name: 'Customers',
  components: {
    CustomerIndividualTable,
  },
  data() {
    return {
      xeroIntegrationStatus: false,
      bankTransactions: [],
    };
  },
  methods: {
    async importTransactions() {
    try {
        const response = await axios.get('https://us-central1-vue-order-fulfillment.cloudfunctions.net/generateBankStatementCSV', {
            responseType: 'blob' // Important: set the response type to 'blob'
        });

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Create a link element and set the href to the Blob URL
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'bank_statement.csv'; // Set the file name for download
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link element from the document
        document.body.removeChild(link);

        alert('Transactions imported successfully');
    } catch (error) {
        console.error('Error importing transactions to Xero:', error);
        alert('Error importing transactions to Xero');
    }
},
async fetchXeroBankTransactions() {
      try {
        const response = await axios.get('https://us-central1-vue-order-fulfillment.cloudfunctions.net/getXeroBankTransactions');
        this.bankTransactions = response.data;
        console.log('Bank transactions fetched successfully:', this.bankTransactions);
      } catch (error) {
        console.error('Error fetching Xero bank transactions:', error);
        alert('Error fetching Xero bank transactions');
      }
    }
  },
  async created() {
    this.xeroIntegrationStatus = await setupXeroConnection();
  }
}
</script>
