// Sets the correct Vue data property to its corresponding input field value
export const updateInput = function (input) {
    this[input.data] = input.value;
}
// Takes an array of strings that represent the form fields. Sets their initial
// value as "null" in the Vue data() return object
export const initFormData = (array) => {
    return array.reduce((acc, data) => ({ ...acc, [data]: null }), {});
}
//
export const updateValid = function (data) {
    this.valid = data;
}
//
export const refreshInput = function (data) {
    this.form.fields.forEach((item) => {
        item.inputs.forEach((input) => {
            if (input.type === "select" || input.value === null) return;
            this[input.data] = data;
        })
    })
    return data
}
// Maps database data to form template
/* Receives FOUR params:
        - An array of strings representing form fields
        - The database query for Firebase collection
        - "to", and "next" are router props
*/
export const getFormData = function (array, dbQuery, to, next) {
    dbQuery
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                //vm assigns all the values we want to put in the template
                next((vm) => {
                    //Sets template values to those stored in the database
                    array
                        .forEach((item) => {
                            vm[item] = doc.data()[item];
                        });
                    vm.valid = true;
                });
            });
        })
        .catch((error) => this.error = error)
}
//
export const assignFormData = function (array, dbQuery) {
    dbQuery
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                //Sets template values to those stored in the database
                array
                    .forEach((item) => {
                        this[item] = doc.data()[item];
                    });
            });
        })
        .catch((error) => this.error = error)
}

// Field validation rules that can be applied to specific form fields
export const fieldRules = {
    fieldRules: {
        required: (v) => !!v || "This field is required",
        maxLength: (v) =>
            (!!v && v.length < 60) || "Must be more than 60 characters long",
        minLength: (v) =>
            !!v.length > 10 || "Must be less than 10 characters long",
        validEmail: (v) => {
            const regex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
                String(v).toLowerCase().match(regex) ||
                "Must be a valid email address"
            );
        },
        validPhone: (v) => {
            const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            return (
                String(v).toLowerCase().match(regex) ||
                "Must be a valid phone number"
            );
        },
    },
}