import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import {
    //BUTTONS
    primaryCTA, secondaryCTA, viewCompaniesBtn, viewIndividualsBtn,
    //DATA TABLE HEADERS
    customersTableHeaders, customersCompaniesTableHeaders,
    //FORM HEADERS
    newCustomerFormHeader,viewCustomerFormHeader, viewCustomerCompaniesFormHeader,
    editCustomerFormHeader, editCustomerCompanyFormHeader,
} from '../data/data'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //BUTTONS
        primaryCTA, secondaryCTA, viewCompaniesBtn, viewIndividualsBtn,
        //DATA TABLE HEADERS
        customersTableHeaders, customersCompaniesTableHeaders,
        //FORM HEADERS
        newCustomerFormHeader,viewCustomerFormHeader, viewCustomerCompaniesFormHeader,
        editCustomerFormHeader, editCustomerCompanyFormHeader,
    },
    mutations: {},
    actions: {},
    modules: {},
});