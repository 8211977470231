<template>
    <v-container  fluid>
            <v-row dense>
                <v-col lg="8" offset-lg="2">
                    <FormHeader v-bind='viewCustomerCompaniesFormHeader'/>
                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                <v-icon color="#3d2cdd">
                                    mdi-office-building
                                </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title>{{customer_name}}</v-list-item-title>
                                <v-list-item-subtitle class="my-2">VAT: {{customer_VAT_number}}</v-list-item-subtitle>
                                <v-list-item-subtitle>Reg: {{customer_registration_number}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider inset></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                <v-icon color="#3d2cdd">
                                    mdi-account
                                </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{customer_contact_person_name}}</v-list-item-title>
                                    <v-list-item-subtitle class="my-2">Email: {{customer_contact_person_email}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Cell: {{customer_contact_person_cell}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider inset></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                <v-icon color="#3d2cdd">
                                    mdi-map-marker
                                </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title>{{customer_addr_1}}</v-list-item-title>
                                <v-list-item-subtitle>{{customer_addr_2}}, {{customer_city}} {{customer_zip}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-card-actions>
                            <!--Edit Customer button
                                Routes to the edit customer view
                            -->
                            <v-spacer></v-spacer>
                            <v-btn
                                :to="{name: 'edit-customer-company', params:{customer_id: customer_id}}"
                                color="#33cc99"
                                text
                                x-large
                            >
                                Edit
                                <v-icon right>mdi-pencil</v-icon>
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
import FormHeader from '../../../components/Forms/FormHeader'
import store from '../store/store'
import {mapState} from 'vuex'

export default {
    name: 'ViewCustomerCompany',
    components: {FormHeader},
    data(){
        return{
            //Initializes form values 
            customer_id: null,
            customer_addr_1: null,
            customer_addr_2: null,
            customer_city: null,
            customer_zip: null,
            customer_status: 'Available',
            customer_date: null,
            customer_timestamp: null,
            loading: false,
            customer_type: 'customer_company',
            customer_name: null,
            customer_VAT_number: null,
            customer_registration_number: null,
            customer_contact_person_name: null,
            customer_contact_person_cell: null,
            customer_contact_person_email: null,
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        viewCustomerCompaniesFormHeader: state => state.viewCustomerCompaniesFormHeader
    }),
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('customers').where('customer_type', '==', 'customer_company')
        .where('customer_id', '==', to.params.customer_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.customer_id = doc.data().customer_id
                    vm.customer_addr_1 = doc.data().customer_addr_1
                    vm.customer_addr_2 = doc.data().customer_addr_2
                    vm.customer_city = doc.data().customer_city
                    vm.customer_zip = doc.data().customer_zip
                    vm.customer_status = doc.data().customer_status
                    vm.customer_type = doc.data().customer_type
                    vm.customer_name = doc.data().customer_name
                    vm.customer_VAT_number = doc.data().customer_VAT_number
                    vm.customer_registration_number = doc.data().customer_registration_number
                    vm.customer_contact_person_name = doc.data().customer_contact_person_name
                    vm.customer_contact_person_cell = doc.data().customer_contact_person_cell
                    vm.customer_contact_person_email = doc.data().customer_contact_person_email
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
            db.collection('customers').where('customer_type', '==', 'customer_company')
            .where('customer_id', '==', this.$route.params.customer_id).get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                db.customer_id = doc.data().customer_id
                    this.customer_id = doc.data().customer_id
                    this.customer_addr_1 = doc.data().customer_addr_1
                    this.customer_addr_2 = doc.data().customer_addr_2
                    this.customer_city = doc.data().customer_city
                    this.customer_zip = doc.data().customer_zip
                    this.customer_status = doc.data().customer_status
                    this.customer_name = doc.data().customer_name
                    this.customer_type = doc.data().customer_type
                    this.customer_VAT_number = doc.data().customer_VAT_number
                    this.customer_registration_number = doc.data().customer_registration_number
                    this.customer_contact_person_name = doc.data().customer_contact_person_name
                    this.customer_contact_person_cell = doc.data().customer_contact_person_cell
                    this.customer_contact_person_email = doc.data().customer_contact_person_email
              })
            })
        },
    }
}
</script>