//This JS file is accessed in the Vuex state block
//It is then passed as a function to the parent component 
//Values are then assigned to component propss

//----------------------------------------------
//BUTTONS DATA
//Primary button data
const primaryCTA = {                
    btn_primary_color: '#33cc99',
    btn_plus_icon: 'mdi-plus',
    btn_ml_10: 'ml-10',
}
//Secondary button data
const secondaryCTA = {
    btn_secondary_color: '#e7e7f7',
    btn_ml_10: 'ml-10',
    btn_view_icon: 'mdi-eye',
}

//View customer companies button data
const viewCompaniesBtn = {
    btn_companies_name: 'View Companies',
    btn_companies_to: '/customers-companies',
}
//View customer individuals button data
const viewIndividualsBtn = {
    btn_companies_name: 'View Individuals',
    btn_companies_to: '/customers',
}

//----------------------------------------------
//DATA TABLE HEADERS
//Customers  table
const customersTableHeaders = [
    { text: 'Edit', value: 'customer_id', align: 'start' },
    { text: 'Name', sortable: true, value: 'customer_name'},
    { text: 'Phone Number', value: 'customer_cell' },
    { text: 'Email', value: 'customer_email' },
    { text: 'Address Line 1', value: 'customer_addr_1' },
    { text: 'Address Line 2', value: 'customer_addr_2' },
    { text: 'City', value: 'customer_city' },
    { text: 'Area Code', value: 'customer_zip' },
    { text: 'Date Added', value: 'customer_date'},
    { text: 'Status', value: 'customer_status'}
]

//Customers  table
const customersCompaniesTableHeaders = [
    { text: 'Edit', value: 'customer_id', align: 'start' },
    { text: 'Company Name', sortable: true, value: 'customer_name'},
    { text: 'VAT #', value: 'customer_VAT_number' },
    { text: 'Contact Person', value: 'customer_contact_person_name' },
    { text: 'Address Line 1', value: 'customer_addr_1' },
    { text: 'Address Line 2', value: 'customer_addr_2' },
    { text: 'City', value: 'customer_city' },
    { text: 'Area Code', value: 'customer_zip' },
    { text: 'Date Added', value: 'customer_date'},
    { text: 'Status', value: 'customer_status'}
]

//----------------------------------------------
//FORM HEADERS

//Add Customer Form Header
const newCustomerFormHeader = {
    form_header_title: 'Add New Customer',
    form_header_to: '/customers',
}
//View Customer Form Header
const viewCustomerFormHeader = {
    form_header_title: 'Customer Details',
    form_header_to: '/customers',
}
//View Customer Form Header
const viewCustomerCompaniesFormHeader = {
    form_header_title: 'Customer Details',
    form_header_to: '/customers-companies',
}
//Edit Customer Form Header
const editCustomerFormHeader = {
    form_header_title: 'Edit Customer',
    form_header_to: '/customers',
}
//Edit Customer Form Header
const editCustomerCompanyFormHeader = {
    form_header_title: 'Edit Customer',
    form_header_to: '/customers-companies',
}

//EXPORTED to the Vuex state block
export {
    //BUTTONS
    primaryCTA, secondaryCTA, viewCompaniesBtn, viewIndividualsBtn,
    //DATA TABLE HEADERS
    customersTableHeaders, customersCompaniesTableHeaders,
    //FORM HEADERS
    newCustomerFormHeader,viewCustomerFormHeader, viewCustomerCompaniesFormHeader,
    editCustomerFormHeader, editCustomerCompanyFormHeader,
}