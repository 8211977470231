<template>
  <div>
    <v-container fluid>
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Customers</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            >
            </v-skeleton-loader>
            <!--Button Options modal-->
            <NewCustomerButton
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('customers_write')"
            />
            <!--Companies button-->
            <ButtonCTA
              :button_name="viewIndividualsBtn.btn_companies_name"
              :button_to="viewIndividualsBtn.btn_companies_to"
              :button_color="secondaryCTA.btn_secondary_color"
              :button_icon="secondaryCTA.btn_view_icon"
              :button_margin_left="secondaryCTA.btn_ml_10"
              v-if="!firstLoad"
            />
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet elevation="4">
            <!--Customer data table-->
            <template>
              <div>
                <v-skeleton-loader
                  :loading="loading_user"
                  type="table"
                  max-height="550"
                  v-if="firstLoad"
                >
                </v-skeleton-loader>
                <v-data-table
                  :headers="customersCompaniesTableHeaders"
                  :items="filteredItems"
                  :search="search"
                  v-if="!firstLoad"
                >
                  <template v-slot:top>
                    <v-container fluid>
                      <v-row>
                        <!--Filter customers by availability status-->
                        <v-col lg="3" md="3">
                          <v-select
                            :items="['Available', 'Unavailable']"
                            v-model="availableStatus"
                            label="Availability Status"
                            outlined
                            flat
                            solo
                            dense
                            append-icon="mdi-filter-variant"
                          >
                            <template>
                              <v-list-item slot="prepend-item">
                                <h5>Filter by Availability</h5>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                        <v-spacer />
                        <!--Search bar filter -->
                        <v-col lg="4" md="4">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            rounded
                            dense
                            filled
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <!--Edit icon button-->
                  <template
                    v-slot:item.customer_id="{ item }"
                    v-if="
                      this.user_roles_list.flat().includes('customers_write')
                    "
                  >
                    <v-btn
                      :to="{
                        name: 'edit-customer-company',
                        params: { customer_id: item.customer_id },
                      }"
                      color="#3d2cdd"
                      icon
                      small
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  <!--Disable edit button based on user roles-->
                  <template v-slot:item.customer_id v-else>
                    <v-btn color="#3d2cdd" icon small disabled
                      ><v-icon small>mdi-pencil-off</v-icon></v-btn
                    >
                  </template>
                  <!--Clickable link that routes from table to job preview page-->
                  <template v-slot:item.customer_name="{ item }">
                    <router-link
                      :to="{
                        path: `/view-customer/${item.customer_id}`,
                      }"
                      ><v-icon left x-small color="#2278cf"
                        >mdi-open-in-new</v-icon
                      >{{ item.customer_name }}</router-link
                    >
                  </template>
                  <!--Contact person slot-->
                  <template v-slot:item.customer_contact_person_name="{ item }">
                    {{ item.customer_contact_person_name }}
                  </template>
                  <template v-slot:item.customer_status="props">
                    <v-icon
                      left
                      x-small
                      :color="formatAvailability(props.item.customer_status)"
                      >mdi-circle</v-icon
                    >{{ props.item.customer_status }}
                  </template>
                  <!--Empty datatable slot-->
                  <template v-slot:no-data>
                    <v-container>
                      <v-row>
                        <v-col>
                          <div class="my-3">
                            <h3>Welcome To Your Customer Dashboard</h3>
                            <p>
                              Here you can add new customers, or view and
                              edit<br />
                              the details of ones already in your system
                            </p>
                            <v-btn
                              color="#33cc99"
                              dark
                              to="/new-customer-company"
                              ><v-icon left>mdi-plus</v-icon>Add Customer</v-btn
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-data-table>
              </div>
            </template>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../components/firebaseInit"; //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import store from "../store/store";
import { mapState } from "vuex";
import ButtonCTA from "../../../components/Buttons/ButtonCTA";
import NewCustomerButton from "../components/NewCustomerButton";

export default {
  components: { ButtonCTA, NewCustomerButton },
  mixins: [mixin_UserRoles],
  name: "CustomerIndividualTable",
  data() {
    return {
      pagination: {},
      search: "",
      customers: [],
      //Used with filtering table by availability
      availableStatus: "Available",
      //Admin permissions
      canReadCustomers: false,
    };
  },
  // Gets customers from firebase datatbase
  created() {
    db.collection("customers")
      .where("customer_type", "==", "customer_company")
      .onSnapshot((querySnapshot) => {
        this.customers = [];
        querySnapshot.forEach((doc) => {
          const data = {
            id: doc.id,
            customer_id: doc.data().customer_id,
            customer_name: doc.data().customer_name,
            customer_addr_1: doc.data().customer_addr_1,
            customer_addr_2: doc.data().customer_addr_2,
            customer_city: doc.data().customer_city,
            customer_zip: doc.data().customer_zip,
            customer_status: doc.data().customer_status,
            customer_date: doc.data().customer_date,
            customer_VAT_number: doc.data().customer_VAT_number,
            customer_registration_number:
              doc.data().customer_registration_number,
            customer_contact_person_name:
              doc.data().customer_contact_person_name,
            customer_contact_person_cell:
              doc.data().customer_contact_person_cell,
            customer_contact_person_email:
              doc.data().customer_contact_person_email,
          };
          //Pushes the customers objects to the empty customers array above
          this.customers.push(data);
        });
      });
  },
  store,
  computed: mapState({
    filteredItems() {
      return this.customers.filter((i) => {
        return (
          this.availableStatus === "" ||
          i.customer_status === this.availableStatus
        );
      });
    },
    //This is where data is retrieved from the Vuex state
    secondaryCTA: (state) => state.secondaryCTA,
    viewIndividualsBtn: (state) => state.viewIndividualsBtn,
    customersCompaniesTableHeaders: (state) =>
      state.customersCompaniesTableHeaders,
  }),
  methods: {
    formatAvailability(customer_status) {
      if (customer_status == "Available") return "#33cc99";
      else return "#f77f9c";
    },
  },
};
</script>

<style scoped>
.white-text {
  color: #fff;
}
</style>