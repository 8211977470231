import axios from 'axios';
import moment from "moment";
import db from '../components/firebaseInit';

const TOKEN_DOC_ID = 'xero_token';
// const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

async function getXeroTokens() {
    const doc = await db.collection('xero_tokens').doc(TOKEN_DOC_ID).get();
    if (!doc.exists) {
        return null;
    }
    const token = doc.data();

    return token;
}

function triggerOAuthFlow() {
    const clientId = process.env.VUE_APP_XERO_CLIENT_ID;
    const redirectUri = encodeURIComponent(process.env.VUE_APP_XERO_CALLBACK_URI);
    const scope = encodeURIComponent('offline_access accounting.contacts accounting.transactions accounting.settings');

    const authUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;

    window.location.href = authUrl;
}

export async function setupXeroConnection() {
    let connectionStatus = false;
    const token = await getXeroTokens();

    if (token && token.accessToken && moment.unix(token.expiryTime).isAfter(moment())) {
        connectionStatus = true;
    } else if (token && token.refreshToken) {
        try {
            const response = await axios.post("https://us-central1-vue-order-fulfillment.cloudfunctions.net/refreshXeroToken");
            if (response.data === 'Token refreshed successfully') {
                connectionStatus = true;
                // Optionally, you can refetch the token from Firestore to update the client state
            } else {
                connectionStatus = false;
                triggerOAuthFlow();
            }
        } catch (error) {
            console.error('Error refreshing Xero token:', error);
            connectionStatus = false;
            triggerOAuthFlow();
        }
    } else {
        connectionStatus = false;
        triggerOAuthFlow();
    }

    return connectionStatus;
}
