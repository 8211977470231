<template>
  <v-card-title>
    <v-btn :to="form_header_to" color="#ffffff" light elevation="0">
      <v-icon left large>mdi-chevron-left</v-icon>
    </v-btn>
    <h3>{{ form_header_title }}</h3>
    <!--Show secondary action button-->
    <v-spacer></v-spacer>
    <v-btn
      elevation="2"
      color="secondary"
      light
      class="ml-10"
      v-if="form_secondary_btn_show"
    >
      {{ form_secondary_button_name }}
      <v-icon right>{{ form_secondary_button_icon }}</v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
export default {
  name: "FormHeader",
  props: {
    form_header_title: {
      type: String,
      defualt: "Form Title",
    },
    form_header_to: {
      type: String,
      defualt: "/",
    },
    form_secondary_button_name: {
      type: String,
      default: "Button Name",
    },
    form_secondary_btn_show: {
      type: Boolean,
      default: false,
    },
    form_secondary_button_icon: {
      type: String,
      default: "mdi-image",
    },
  },
  data() {
    return {
      advanced_fields: false,
    };
  },
};
</script>