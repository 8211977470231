import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { showSnackbar } from '../../../globalActions/index'

export const addCustomer = function () {
    //Form validation --> ensures required fields aren't blank before continuing further
    if (!this.valid) return;
    this.loading = true
    var newDocRef = db.collection('customers').doc();
    newDocRef.set({
        customer_id: newDocRef.id,
        customer_name: this.customer_name,
        customer_cell: this.customer_cell,
        customer_email: this.customer_email,
        customer_addr_1: this.customer_addr_1,
        customer_addr_2: this.customer_addr_2,
        customer_city: this.customer_city,
        customer_zip: this.customer_zip,
        customer_status: this.customer_status,
        customer_type: this.customer_type,
        customer_date: new Date().toISOString().slice(0, 10),
        customer_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        customer_created_by_id: this.customer_created_by_id,
        customer_created_by_name: this.customer_created_by_name,
    })
        .then(() => {
            this.loading = false
            showSnackbar("New customer added successfully");
        }).catch((error) => error)
    this.$router.push("/customers");
}
//
export const updateCustomer = function () {
    //Form validation --> ensures required fields aren't blank before continuing further
    if (!this.valid) return;
    this.loading = true
    db.collection('customers').where('customer_id', '==', this.$route.params.customer_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                doc.ref.update({
                    customer_id: this.customer_id,
                    customer_name: this.customer_name,
                    customer_cell: this.customer_cell,
                    customer_email: this.customer_email,
                    customer_addr_1: this.customer_addr_1,
                    customer_addr_2: this.customer_addr_2,
                    customer_city: this.customer_city,
                    customer_zip: this.customer_zip,
                    customer_status: this.customer_status
                })
                    //Reroute to customer view page after database has been updated
                    .then(() => {
                        this.loading = false
                        showSnackbar("Customer details updated successfully");
                    }).catch((error) => error)
            })
        }).catch((error) => error)
    this.$router.push("/customers");

}
//

export const deleteCustomer = function () {
    db.collection('customers').where('customer_id', '==', this.$route.params.customer_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                doc.ref.delete()
                //Redirects back to customer list page
                this.loading_delete = false
                showSnackbar("Customer deleted successfully");
            })
        }).catch((error) => error)
    this.$router.push('/customers')
}

export const captureUser = function () {
    //Captures user that created the sales order
    this.customer_created_by_id = firebase.auth().currentUser.uid;
    db.collection("users")
        .where("uid", "==", this.customer_created_by_id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                this.customer_created_by_name = doc.data().displayName;
            });
        }).catch((error) => error)
}

// Customer Companies

export const addCustomerCompany = function () {
    //Form validation --> ensures required fields aren't blank before continuing further
    if (!this.valid) return;
    this.loading = true;
    var newDocRef = db.collection("customers").doc();
    newDocRef
        .set({
            customer_id: newDocRef.id,
            customer_addr_1: this.customer_addr_1,
            customer_addr_2: this.customer_addr_2,
            customer_city: this.customer_city,
            customer_zip: this.customer_zip,
            customer_status: this.customer_status,
            customer_date: new Date().toISOString().slice(0, 10),
            customer_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            customer_created_by_id: this.customer_created_by_id,
            customer_created_by_name: this.customer_created_by_id,
            customer_name: this.customer_name,
            customer_type: this.customer_type,
            customer_VAT_number: this.customer_VAT_number,
            customer_registration_number: this.customer_registration_number,
            customer_contact_person_name: this.customer_contact_person_name,
            customer_contact_person_cell: this.customer_contact_person_cell,
            customer_contact_person_email: this.customer_contact_person_email,
        })
        //Reroute to customers list after database is updated
        .then(() => {
            this.loading = false;
            showSnackbar("New customer added successfully");
        }).catch((error) => error)
    this.$router.push("/customers");
}

//
export const updateCustomerCompany = function () {
    //Form validation --> ensures required fields aren't blank before continuing further
    if (!this.valid) return;
    this.loading = true;
    db.collection("customers")
        .where("customer_id", "==", this.$route.params.customer_id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref
                    .update({
                        customer_id: this.customer_id,
                        customer_name: this.customer_name,
                        customer_addr_1: this.customer_addr_1,
                        customer_addr_2: this.customer_addr_2,
                        customer_city: this.customer_city,
                        customer_zip: this.customer_zip,
                        customer_status: this.customer_status,
                        customer_type: this.customer_type,
                        customer_VAT_number: this.customer_VAT_number,
                        customer_registration_number:
                            this.customer_registration_number,
                        customer_contact_person_name:
                            this.customer_contact_person_name,
                        customer_contact_person_cell:
                            this.customer_contact_person_cell,
                        customer_contact_person_email:
                            this.customer_contact_person_email,
                    })
                    //Reroute to customer view page after database has been updated
                    .then(() => {
                        this.loading = false;
                        showSnackbar("Customer details updated successfully");
                    }).catch((error) => error)
                this.$router.push("/customers-companies");
            });
        }).catch((error) => error)
}