<template>
  <v-btn
    elevation="2"
    :color="button_color"
    :class="button_margin_left"
    :to="button_to"
  >
    {{ button_name }}
  </v-btn>
</template>

<script>
export default {
  props: {
    button_name: {
      type: String,
      default: "Primary Button",
    },
    button_color: {
      type: String,
      default: "primary",
    },
    button_to: {
      type: String,
      deafult: "/",
    },
    button_icon: {
      type: String,
      deafult: "mdi-image",
    },
    button_margin_left: {
      type: String,
      default: "ml-10",
    },
  },
};
</script>