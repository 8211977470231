<template>
    <v-menu
      bottom
      open-on-hover
      :offset-y="offset"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            elevation="2"
            color="#33cc99"
            dark
            class="ml-10"
            v-bind="attrs"
            v-on="on"
        >
            Add Customer
            <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
        </template>
        <v-list>
        <v-list-item
          v-for="(item, index) in addOptions"
          :key="index"
          link
          :to="item.to"
        >
        <v-icon left>{{ item.icon }}</v-icon>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
  export default {
    data: () => ({
      addOptions: [
        { title: 'Individual', icon: 'mdi-account-plus', to: '/new-customer-individual' },
        { title: 'Company', icon: 'mdi-office-building', to: '/new-customer-company' },
      ],
      offset: true,
    }),
  }
</script>