<template>
  <v-container>
    <v-row>
      <v-col>
        <!--Header-->
        <v-card-title>
          <v-btn @click="$router.go(-1)" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>{{ customer_name }}</h3>
          <v-spacer></v-spacer>
        </v-card-title>
      </v-col>
    </v-row>
    <!--Tabs-->
    <v-row>
      <v-col>
        <v-sheet elevation="4">
          <!--Tabs-->
          <v-tabs show-arrows background-color="indigo" dark>
            <v-tab
              class="mr-3"
              v-for="item in tabData"
              :key="item.name"
              :href="'#tab-' + item.name"
            >
              {{ item.name }}
            </v-tab>
            <!--Data tables-->
            <v-tab-item
              v-for="item in tabData"
              :key="item.component"
              :value="'tab-' + item.name"
            >
              <!--Dynamically render component based on tab-->
              <component
                :is="item.component"
                :customer_id="customer_id"
                :customer_name="customer_name"
              >
              </component>
            </v-tab-item>
          </v-tabs>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "../../../components/firebaseInit";
export default {
  name: "ViewCustomer",
  components: {
    CustomerQuotesTable: () =>
      import("../components/Tables/CustomerQuotesTable.vue"),
    CustomerProFormasTable: () =>
      import("../components/Tables/CustomerProFormasTable.vue"),
    CustomerInvoicesTable: () =>
      import("../components/Tables/CustomerInvoicesTable.vue"),
    CustomerPaymentsTable: () =>
      import("../components/Tables/CustomerPaymentsTable.vue"),
    CustomerWorkOrdersTable: () =>
      import("../components/Tables/CustomerWorkOrdersTable.vue"),
    CustomerDetails: () => import("../components/CustomerDetails.vue"),
  },
  data() {
    return {
      customer_id: null,
      customer_name: null,
      customer_type: null,
      tabData: [
        {
          name: "Customer Details",
          component: "CustomerDetails",
        },
        { name: "Quotes", component: "CustomerQuotesTable" },
        { name: "Pro-Formas", component: "CustomerProFormasTable" },
        { name: "Invoices", component: "CustomerInvoicesTable" },
        { name: "Payments", component: "CustomerPaymentsTable" },
        { name: "Work Orders", component: "CustomerWorkOrdersTable" },
        //{ name: "Payment Refunds", component: "" },
        //{ name: "Credit Notes", component: "" },
        //{ name: "Credit Note Refunds", component: "" },
        //{ name: "Statements", component: "" },
        //{ name: "Deliveries", component: "" },
      ],
    };
  },
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("customers")
      .where("customer_id", "==", to.params.customer_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //vm assigns all the values we want to put in the template
          next((vm) => {
            //Sets template values to those stored in the database
            vm.customer_id = doc.data().customer_id;
            vm.customer_name = doc.data().customer_name;
            vm.customer_type = doc.data().customer_type;
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    //Maps database data to template relative to the customer selected in this route
    fetchData() {
      db.collection("customers")
        .where("customer_id", "==", this.$route.params.customer_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.customer_id = doc.data().customer_id;
            this.customer_name = doc.data().customer_name;
            this.customer_type = doc.data().customer_type;
          });
        });
    },
  },
};
</script>